import Drawer from '@material-ui/core/Drawer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import styled from 'styled-components';
import { Header } from './components/Header';
import { Invoice } from './components/invoice/Invoice';
import { InvoiceSidebar } from './components/invoice/InvoiceSidebar';
import { InvoiceViewPreview } from './components/invoice/InvoiceViewPreview';
import { Layout } from './components/Layout';
import { Analytics } from './services/analytics';
import { usePdf } from './stores/pdf';

const drawerWidth = 300;

const useStyles = makeStyles({
    drawer: {
        position: 'sticky',
        top: 64,
        alignSelf: 'flex-start',
        minHeight: '100%',
    },
    drawerPaper: {
        backgroundColor: 'rgb(252, 252, 252)',
        width: drawerWidth,
        height: '100vh',
        borderRight: '2px solid rgba(0, 0, 0, 0.12)',
        position: 'static',
    },
});

const Container = styled.div`
    padding: 2rem;
    background-color: #fafafa;
    min-height: 100vh;
    flex: 1;
`;

const ContentContainer = styled.div`
    display: flex;
`;

export function App() {
    Analytics.useTrackPageView('/');

    const { isDownloading } = usePdf();
    const classes = useStyles();

    return (
        <Layout>
            {isDownloading ? (
                <InvoiceViewPreview />
            ) : (
                <>
                    <Header />

                    <ContentContainer>
                        <Drawer
                            className={classes.drawer}
                            variant={'permanent'}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                        >
                            <InvoiceSidebar />
                        </Drawer>

                        <Container>
                            <Invoice />
                        </Container>
                    </ContentContainer>
                </>
            )}
        </Layout>
    );
}
