import Button from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';
import { useCurrencyConverter } from '../../hooks/currency-converter';
import { useInvoice } from '../../stores/invoice';
import { UiInput } from '../ui/UiInput';
import { InvoiceSummary } from './InvoiceSummary';

const Wrapper = styled.table`
    border-collapse: collapse;
    width: 100%;

    thead {
        color: #aaa;
        font-weight: bold;
        font-size: 0.75rem;

        td {
            border-bottom: 1px solid #ddd;
            white-space: nowrap;
        }
    }

    td {
        padding: 0.25rem;
    }

    td.number {
        text-align: right;
    }

    td:last-child {
        text-align: right;
    }
`;

const Row = styled.tr`
    border: 0;

    :nth-child(even) {
        background-color: #fafafa;
    }
`;

export function InvoiceViewEditItems() {
    const { addItem, invoice } = useInvoice();

    return (
        <Wrapper>
            {invoice.items.length > 0 && (
                <thead>
                    <tr>
                        <td>Description</td>
                        <td>Quantity</td>
                        <td>Unit Price (incl VAT)</td>
                        <td>VAT (%)</td>
                        <td>Total</td>
                        <td />
                    </tr>
                </thead>
            )}

            <tbody>
                {invoice.items.map(item => (
                    <InvoiceItemRow key={item.id} itemId={item.id} />
                ))}

                <tr>
                    <td colSpan={6}>
                        <Button
                            onClick={addItem}
                            variant={invoice.items.length === 0 ? 'contained' : 'outlined'}
                            color={'primary'}
                            disabled={invoice.isDownloading}
                            fullWidth
                        >
                            Add row
                        </Button>
                    </td>
                </tr>

                {invoice.items.length > 0 && <InvoiceSummary />}
            </tbody>
        </Wrapper>
    );
}

function InvoiceItemRow({ itemId }: any) {
    const { findItem, updateItem, removeItemById, invoice } = useInvoice();
    const { convert } = useCurrencyConverter();

    const item = findItem(itemId);

    if (!item) {
        return null;
    }

    const { id, description, count, priceIncludingVat, vat } = item;
    const unitVat = priceIncludingVat / (vat / 100 + 1);
    const priceExcludingVat = priceIncludingVat / unitVat;
    const unitPrice = priceIncludingVat;
    const update = updateItem(item.id);

    return (
        <Row>
            <td>
                <UiInput
                    value={description}
                    onChangeValue={(description: string) => update({ description })}
                    placeholder={'Description'}
                    disabled={invoice.isDownloading}
                />
            </td>

            <td>
                <UiInput
                    value={count}
                    onChangeValue={(count: number) => update({ count })}
                    number
                    placeholder={'Count'}
                    disabled={invoice.isDownloading}
                />
            </td>

            <td>
                <UiInput
                    value={priceIncludingVat}
                    onChangeValue={(priceIncludingVat: number) => {
                        update({ priceIncludingVat });
                    }}
                    currency
                    disabled={invoice.isDownloading}
                />
            </td>

            <td>
                <UiInput
                    value={vat}
                    onChangeValue={(vat: number) => update({ vat })}
                    number
                    placeholder={'VAT %'}
                    disabled={invoice.isDownloading}
                />
            </td>

            <td>{convert(unitPrice * count)}</td>

            <td>
                <Button
                    onClick={() => removeItemById(id)}
                    disabled={invoice.isDownloading}
                    variant={'text'}
                    color={'secondary'}
                >
                    Remove
                </Button>
            </td>
        </Row>
    );
}
