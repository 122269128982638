import { createStore } from './index';
import { useInvoice, ViewMode } from './invoice';

export interface Pdf {
    isDownloading: boolean;
}

const { useStore, set } = createStore<Pdf>({
    isDownloading: false,
});

export function usePdf() {
    const { setViewMode } = useInvoice();
    const [state, setState] = useStore();
    const { isDownloading } = state;

    return {
        isDownloading,
        setDownloading(isDownloading: boolean) {
            setState(state => {
                state.isDownloading = isDownloading;
            });

            setViewMode(ViewMode.Preview);
        },
    };
}
