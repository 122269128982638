import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components';
import { useInvoice } from '../../stores/invoice';
import { InvoiceViewEdit } from './InvoiceViewEdit';
import { InvoiceViewPreview } from './InvoiceViewPreview';

const PreviewWrapper = styled(Card)`
    position: relative;
    margin: 0 auto;
    display: flex;
`;

const Loader = styled.div`
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
`;

export function Invoice() {
    const { invoice, isViewModeEdit } = useInvoice();

    return (
        <PreviewWrapper>
            {invoice.isDownloading && (
                <Loader>
                    <CircularProgress />
                </Loader>
            )}

            {isViewModeEdit() ? <InvoiceViewEdit /> : <InvoiceViewPreview />}
        </PreviewWrapper>
    );
}
