import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import CancelRounded from '@material-ui/icons/CancelRounded';
import { DatePicker } from '@material-ui/pickers';
import { addWeeks } from 'date-fns';
import React from 'react';
import styled from 'styled-components';
import { useCurrencyConverter } from '../../hooks/currency-converter';
import { useInvoice, ViewMode } from '../../stores/invoice';
import { UiFileSelect } from '../ui/UiFileSelect';
import { UiFlex } from '../ui/UiFlex';
import { UiGrid } from '../ui/UiGrid';
import { InvoiceTextField } from './InvoiceTextField';
import { InvoiceViewEditItems } from './InvoiceViewEditItems';

const Wrapper = styled(UiGrid)`
    padding: 2rem;
    width: 100%;

    .header {
        background: #eee;
        padding: 1rem;
        border-radius: 0.25rem;
    }
`;

const DatepickerWrapper = styled.div`
    display: flex;
    align-items: center;

    > *:not(:first-child) {
        margin-left: 0.5rem;
    }
`;

const AddressesWrapper = styled(UiFlex)`
    > * {
        flex: 1;
    }
`;

export function InvoiceViewEdit() {
    const { currencies } = useCurrencyConverter();
    const { invoice, updateInvoice, setViewMode } = useInvoice();
    const { fromLogo, sentAt, deliveryDate, dueDate } = invoice;

    return (
        <Wrapper spacing={4}>
            <InvoiceTextField label={'Invoice ID'} field={'number'} variant={'outlined'} />

            <UiGrid>
                <Typography variant={'h3'} color={'textSecondary'} className={'header'}>
                    General
                </Typography>

                <div>
                    <UiFileSelect
                        fileBase64={fromLogo}
                        setFileBase64={fromLogo => updateInvoice({ fromLogo })}
                        onRemove={() => updateInvoice({ fromLogo: undefined })}
                    />
                </div>

                <InvoiceTextField label={'Company name'} field={'fromName'} variant={'outlined'} />

                <InvoiceTextField field={'currency'} select variant={'outlined'}>
                    {currencies.map(currency => (
                        <MenuItem key={currency.value} value={currency.value}>
                            {currency.label}
                        </MenuItem>
                    ))}
                </InvoiceTextField>

                <UiFlex>
                    <InvoiceTextField label={'Phone'} field={'fromPhone'} variant={'outlined'} />
                    <InvoiceTextField label={'Email'} field={'fromEmail'} variant={'outlined'} />
                </UiFlex>
            </UiGrid>

            <UiGrid>
                <Typography variant={'h3'} color={'textSecondary'} className={'header'}>
                    Dates
                </Typography>

                <UiFlex flexChildren>
                    <DatePicker
                        variant="inline"
                        label={'Issue Date'}
                        value={sentAt}
                        onChange={(sentAt: any) => {
                            updateInvoice({ sentAt });

                            if (!dueDate) {
                                updateInvoice({ dueDate: addWeeks(sentAt, 2) });
                            }
                        }}
                        disabled={invoice.isDownloading}
                        inputVariant={'outlined'}
                    />

                    <DatePicker
                        variant="inline"
                        label={'Due Date'}
                        value={dueDate}
                        onChange={dueDate => updateInvoice({ dueDate })}
                        disabled={invoice.isDownloading}
                        minDate={sentAt}
                        inputVariant={'outlined'}
                    />

                    <DatepickerWrapper>
                        <DatePicker
                            variant="inline"
                            label={'Delivery Date'}
                            value={deliveryDate}
                            onChange={(deliveryDate: any) => updateInvoice({ deliveryDate })}
                            disabled={invoice.isDownloading}
                            inputVariant={'outlined'}
                        />

                        <Button
                            size={'small'}
                            onClick={() => {
                                updateInvoice({ deliveryDate: null });
                            }}
                        >
                            <CancelRounded color={'secondary'} />
                        </Button>
                    </DatepickerWrapper>
                </UiFlex>
            </UiGrid>

            <UiGrid>
                <Typography variant={'h3'} color={'textSecondary'} className={'header'}>
                    Addresses
                </Typography>

                <AddressesWrapper>
                    <UiGrid>
                        <Typography variant={'h4'} color={'textSecondary'}>
                            From
                        </Typography>

                        <UiGrid>
                            <InvoiceTextField label={'Street'} field={'fromStreet'} variant={'outlined'} />
                            <InvoiceTextField label={'City'} field={'fromCity'} variant={'outlined'} />
                            <InvoiceTextField label={'ZIP code'} field={'fromZipCode'} variant={'outlined'} />
                            <InvoiceTextField label={'Country'} field={'fromCountry'} variant={'outlined'} />
                        </UiGrid>
                    </UiGrid>

                    <UiGrid>
                        <Typography variant={'h4'} color={'textSecondary'}>
                            To
                        </Typography>

                        <UiGrid>
                            <InvoiceTextField label={'Company name'} field={'toName'} variant={'outlined'} />
                            <InvoiceTextField label={'Street'} field={'toStreet'} variant={'outlined'} />
                            <InvoiceTextField label={'City'} field={'toCity'} variant={'outlined'} />
                            <InvoiceTextField label={'ZIP code'} field={'toZipCode'} variant={'outlined'} />
                            <InvoiceTextField label={'Country'} field={'toCountry'} variant={'outlined'} />
                        </UiGrid>
                    </UiGrid>

                    <UiGrid>
                        <Typography variant={'h4'} color={'textSecondary'}>
                            Delivery address
                        </Typography>

                        <UiGrid>
                            <InvoiceTextField label={'Street'} field={'shippingStreet'} variant={'outlined'} />
                            <InvoiceTextField label={'City'} field={'shippingCity'} variant={'outlined'} />
                            <InvoiceTextField label={'ZIP code'} field={'shippingZipCode'} variant={'outlined'} />
                            <InvoiceTextField label={'Country'} field={'shippingCountry'} variant={'outlined'} />
                        </UiGrid>
                    </UiGrid>
                </AddressesWrapper>
            </UiGrid>

            <UiGrid>
                <Typography variant={'h3'} color={'textSecondary'} className={'header'}>
                    Tax & Billing
                </Typography>

                <InvoiceTextField label={'VAT Number'} field={'vatNumber'} variant={'outlined'} />
                <InvoiceTextField label={'Company Reg. Code'} field={'registrationCode'} variant={'outlined'} />
                <InvoiceTextField field={'bankAccount'} variant={'outlined'} />
            </UiGrid>

            <UiGrid>
                <Typography variant={'h3'} color={'textSecondary'} className={'header'}>
                    Items
                </Typography>

                <InvoiceViewEditItems />
            </UiGrid>

            <UiGrid>
                <Typography variant={'h3'} color={'textSecondary'} className={'header'}>
                    Extra
                </Typography>

                <UiGrid>
                    <InvoiceTextField label={'Notes'} field={'notes'} variant={'outlined'} />
                    <InvoiceTextField label={'Terms'} field={'terms'} variant={'outlined'} />
                </UiGrid>
            </UiGrid>

            <Button
                onClick={() => setViewMode(ViewMode.Preview)}
                variant={'contained'}
                color={'primary'}
                size={'large'}
                fullWidth
            >
                Preview
            </Button>
        </Wrapper>
    );
}
