import InputAdornment from '@material-ui/core/InputAdornment';
import Input, { InputProps } from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { useCurrencyConverter } from '../../hooks/currency-converter';

interface Props extends InputProps {
    label?: string;
    type?: string;
    currency?: boolean;
    value: any;
    placeholder?: string;
    onChangeValue?: (value: any) => void;
    number?: boolean;
    select?: boolean;
    options?: {
        label: any;
        value: any;
    }[];
}

export function UiInput({
    label,
    value,
    placeholder,
    number,
    type,
    onChange,
    onChangeValue,
    inputRef,
    currency,
    ...rest
}: Props) {
    const { getCurrencySymbol } = useCurrencyConverter();

    if (rest.options) {
        rest.select = true;

        rest.children = rest.options.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
                {label}
            </MenuItem>
        ));
    }

    if (currency) {
        number = true;
        rest.startAdornment = <InputAdornment position="start">{getCurrencySymbol()}</InputAdornment>;
    }

    return (
        <Input
            {...rest}
            placeholder={placeholder || label}
            value={value}
            onChange={event => {
                if (onChange) {
                    onChange(event);
                }

                if (onChangeValue) {
                    if (number) {
                        onChangeValue(event.target.value ? Number(event.target.value) : '');
                    } else {
                        onChangeValue(event.target.value);
                    }
                }
            }}
            type={type || (number ? 'number' : 'text')}
            inputRef={inputRef}
            inputProps={{
                autoCapitalize: 'none',
                autoCorrect: 'off',
            }}
        />
    );
}
