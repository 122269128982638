import React, { useMemo } from 'react';
import styled from 'styled-components';

const Image = styled.img`
    max-height: 6rem;
    max-width: 6rem;
`;

interface Props {
    file?: File;
    fileBase64?: string;
    fromLogo?: string;
    onRemove?: () => void;
}

export function UiFilePreview({ file, fileBase64 }: Props) {
    const preview = useMemo(() => {
        if (file) {
            return URL.createObjectURL(file);
        }
    }, [file]);

    if (fileBase64) {
        return <Image src={fileBase64} alt={'preview'} />;
    }

    if (preview) {
        return <Image src={preview} alt={'preview'} />;
    }

    return null;
}
