import coreColor from '@material-ui/core/colors/blue';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

export const fontFamily = `Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;

export const breakpoints = {
    tablet: 768,
};

export const media = {
    tabletUp: `@media (min-width:${breakpoints.tablet}px)`,
    downloadPdf: '@media print',
};

export const theme = createMuiTheme({
    palette: {
        background: {
            default: '#fff',
        },
        primary: coreColor,
        text: {
            //primary: '#484848',
        },
    },
    typography: {
        fontFamily,
        fontSize: 16,
        h1: { fontSize: '1.5rem', fontWeight: 400 },
        h2: { fontSize: '1.25rem', fontWeight: 500 },
        h3: { fontSize: '1.25rem', fontWeight: 500 },
        h4: { fontSize: '0.9rem', fontWeight: 700 },
        h5: { fontSize: '0.8rem' },
        h6: { fontSize: '0.75rem' },
        body1: { fontSize: '1rem' },
    },
    overrides: {
        MuiButton: {
            root: {
                borderRadius: 6,
                whiteSpace: 'nowrap',
                textTransform: 'initial',
                textDecoration: 'none',
                lineHeight: '1rem',
                padding: '12px 1rem',
                [media.downloadPdf]: {
                    display: 'none',
                },
            },
            outlined: {
                padding: '11px 1rem',
            },
            text: {
                padding: '12px 1rem',
            },
            contained: {
                backgroundColor: 'white',
                color: 'rgb(72, 72, 72);',
                '&$disabled': {
                    opacity: 0.5,
                    color: '#ccc',
                },
            },
            sizeSmall: {
                minHeight: '2rem',
            },
            sizeLarge: {
                fontSize: '1.2rem',
                minHeight: '3rem',
            },
        },
        MuiInput: {
            underline: {
                '&::before': {
                    borderBottomColor: 'rgba(0, 0, 0, 0.25)',
                },
            },
        },
        MuiInputBase: {
            root: {
                fontSize: 'inherit',
            },
            input: {},
        },
        MuiInputLabel: {
            outlined: {
                transform: `translate(0.8rem, 0.8rem) scale(1)`,
            },
        },
        MuiOutlinedInput: {
            input: {
                padding: 12,
                paddingRight: 2,
            },
        },
        MuiFormControl: {
            root: {
                width: '100%',
                marginTop: 4,
            },
            marginNormal: {
                marginTop: 0,
                marginBottom: 0,
            },
        },
        MuiCircularProgress: {
            colorSecondary: {
                color: 'white',
            },
        },
        MuiBottomNavigationAction: {
            root: {
                //padding: 0,
                minWidth: 40,
            },
            label: {
                fontSize: '0.5rem',
                marginTop: 4,
                '&$selected': {
                    fontSize: '0.5rem',
                },
            },
        },
        MuiTableCell: {
            root: {
                padding: 4,
            },
            head: {
                color: '#666',
                textTransform: 'uppercase',
                fontWeight: 700,
                whiteSpace: 'nowrap',
            },
            body: {
                fontSize: '0.9rem',
            },
        },
        MuiStepper: {
            root: {
                padding: 0,
            },
        },
        MuiStepButton: {
            root: {
                margin: 0,
                padding: 0,
            },
        },
        MuiFab: {
            root: {
                height: 64,
                width: 64,
                fontSize: 48,
            },
        },
        MuiTab: {
            root: {
                fontSize: '0.75rem',
            },
        },
        MuiTabs: {
            indicator: {
                borderRadius: 12,
            },
        },
        MuiSvgIcon: {
            fontSizeSmall: {
                fontSize: 16,
            },
        },
    },
});
