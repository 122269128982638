import React from 'react';
import styled from 'styled-components';
import { UiGrid } from '../ui/UiGrid';
import { InvoiceFooter } from './InvoiceFooter';
import { InvoiceHeader } from './InvoiceHeader';
import { InvoiceItemTable } from './InvoiceItemTable';

const Wrapper = styled(UiGrid)<any>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2rem;
`;

const Content = styled.div`
    position: relative;
    margin-top: 2rem;
`;

export function InvoiceViewPreview() {
    return (
        <Wrapper spacing={8} id={'invoice-preview'}>
            <InvoiceHeader />

            <Content>
                <InvoiceItemTable />
            </Content>

            <InvoiceFooter />
        </Wrapper>
    );
}
