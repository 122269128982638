import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import { formatDate } from '../../services/date';
import { useInvoice } from '../../stores/invoice';
import { UiFilePreview } from '../ui/UiFilePreview';
import { UiFlex } from '../ui/UiFlex';

const TableInfo = styled.table`
    .header {
        font-weight: bold;
        margin-bottom: 2rem;
    }

    td {
        padding: 0;

        &:first-child {
            padding-right: 1rem;
        }
    }
`;

export function InvoiceHeader() {
    const { invoice } = useInvoice();
    const { fromLogo, number, sentAt, deliveryDate, dueDate } = invoice;

    return (
        <>
            <UiFlex justifySpaceBetween>
                <TableInfo>
                    <tbody>
                        <tr>
                            <td colSpan={2}>
                                <Typography variant={'h3'} className={'header'}>
                                    Invoice
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Typography>Invoice ID:</Typography>
                            </td>
                            <td>
                                <Typography>{number}</Typography>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Typography>Issue Date:</Typography>
                            </td>
                            <td>
                                <Typography>{formatDate(sentAt)}</Typography>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <Typography>Due Date:</Typography>
                            </td>
                            <td>
                                <Typography>{formatDate(dueDate)}</Typography>
                            </td>
                        </tr>

                        {deliveryDate && (
                            <tr>
                                <td>
                                    <Typography>Delivery Date:</Typography>
                                </td>
                                <td>
                                    <Typography>{formatDate(deliveryDate)}</Typography>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </TableInfo>

                <UiFilePreview fileBase64={fromLogo} />
            </UiFlex>

            <UiFlex flexChildren>
                <div>
                    <Typography variant={'h6'} color={'textSecondary'}>
                        From
                    </Typography>
                    <Typography variant={'h4'}>{invoice.fromName}</Typography>
                    <Typography>
                        {invoice.fromStreet} {invoice.fromZipCode}
                    </Typography>
                    <Typography>
                        {invoice.fromCity} {invoice.fromCountry}
                    </Typography>
                    {invoice.fromPhone && <Typography>Phone: {invoice.fromPhone}</Typography>}
                    {invoice.fromEmail && <Typography>Email: {invoice.fromEmail}</Typography>}
                </div>

                <div>
                    <Typography variant={'h6'} color={'textSecondary'}>
                        For
                    </Typography>
                    <Typography variant={'h4'}>{invoice.toName}</Typography>
                    <Typography>
                        {invoice.toStreet} {invoice.toZipCode}
                    </Typography>
                    <Typography>
                        {invoice.toCity} {invoice.toCountry}
                    </Typography>
                </div>

                {invoice.shippingStreet && (
                    <>
                        <div>
                            <Typography variant={'h6'} color={'textSecondary'}>
                                Delivery
                            </Typography>
                            <Typography>
                                {invoice.shippingStreet} {invoice.shippingZipCode}
                            </Typography>
                            <Typography>
                                {invoice.shippingCity} {invoice.shippingCountry}
                            </Typography>
                        </div>
                    </>
                )}
            </UiFlex>

            {/*<Wrapper>*/}
            {/*    <UiGrid spacing={6}>*/}
            {/*        <UiGrid spacing={1}>*/}
            {/*            <TableInfo>*/}
            {/*                <tbody>*/}
            {/*                    <tr>*/}
            {/*                        <td>*/}
            {/*                            <Typography color={'textSecondary'}>Invoice ID:</Typography>*/}
            {/*                        </td>*/}
            {/*                        <td>{number}</td>*/}
            {/*                    </tr>*/}

            {/*                    <tr>*/}
            {/*                        <td>*/}
            {/*                            <Typography color={'textSecondary'}>Issue Date:</Typography>*/}
            {/*                        </td>*/}
            {/*                        <td>{formatDate(sentAt)}</td>*/}
            {/*                    </tr>*/}

            {/*                    <tr>*/}
            {/*                        <td>*/}
            {/*                            <Typography color={'textSecondary'}>Due Date:</Typography>*/}
            {/*                        </td>*/}
            {/*                        <td>{formatDate(dueDate)}</td>*/}
            {/*                    </tr>*/}

            {/*                    {deliveryDate && (*/}
            {/*                        <tr>*/}
            {/*                            <td>*/}
            {/*                                <Typography color={'textSecondary'}>Delivery Date:</Typography>*/}
            {/*                            </td>*/}
            {/*                            <td>{formatDate(deliveryDate)}</td>*/}
            {/*                        </tr>*/}
            {/*                    )}*/}
            {/*                </tbody>*/}
            {/*            </TableInfo>*/}
            {/*        </UiGrid>*/}
            {/*    </UiGrid>*/}

            {/*    /!*<UiGrid>*!/*/}
            {/*    /!*    <InvoiceFromTo />*!/*/}
            {/*    /!*</UiGrid>*!/*/}
            {/*</Wrapper>*/}
        </>
    );
}
