import { useInvoice } from '../stores/invoice';
import find from 'lodash/find';

export function useCurrencyConverter() {
    const { currency } = useInvoice().invoice;

    const currencies = [
        {
            label: 'Euro',
            value: 'EUR',
            locale: 'de-de',
        },
        {
            label: 'Swedish Krona',
            value: 'SEK',
            locale: 'sv-se',
        },
        {
            label: 'Norwegian Krone',
            value: 'NOK',
            locale: 'no-no',
        },
        {
            label: 'Pound Sterling',
            value: 'GBP',
            locale: 'en-gb',
        },
        {
            label: 'US Dollar',
            value: 'USD',
            locale: 'en-us',
        },
    ];

    const currencyItem = find(currencies, { value: currency });
    const locale = currencyItem ? currencyItem.locale : undefined;

    return {
        getCurrencySymbol() {
            return Number(0)
                .toLocaleString(locale, {
                    style: 'currency',
                    currency,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                })
                .replace('0', '');
        },
        convert(number: string | number) {
            return Number(number).toLocaleString(locale, { style: 'currency', currency });
        },
        currencies,
    };
}
