import Button from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';
import { useInvoice } from '../../stores/invoice';
import { InvoiceItemRow } from './InvoiceItemRow';
import { InvoiceSummary } from './InvoiceSummary';

const Wrapper = styled.table`
    border-collapse: collapse;
    width: 100%;

    thead {
        color: #aaa;
        font-weight: bold;
        font-size: 0.75rem;

        td {
            border-bottom: 1px solid #ddd;
            white-space: nowrap;
        }
    }

    td {
        padding: 0.25rem;
    }

    td.number {
        text-align: right;
    }

    td:last-child {
        text-align: right;
    }
`;

export function InvoiceItemTable() {
    const { invoice, addItem, isViewModeEdit } = useInvoice();

    return (
        <Wrapper>
            {invoice.items.length > 0 && (
                <thead>
                    <tr>
                        <td>Description</td>
                        <td>Quantity</td>
                        <td>Unit Price</td>
                        <td>VAT (%)</td>
                        <td>Total</td>
                        {isViewModeEdit() && <td />}
                    </tr>
                </thead>
            )}

            <tbody>
                {invoice.items.map(item => (
                    <InvoiceItemRow key={item.id} itemId={item.id} />
                ))}

                {isViewModeEdit() && (
                    <tr>
                        <td colSpan={6}>
                            <Button
                                onClick={addItem}
                                variant={invoice.items.length === 0 ? 'contained' : 'outlined'}
                                color={'primary'}
                                disabled={invoice.isDownloading}
                                fullWidth
                            >
                                Add row
                            </Button>
                        </td>
                    </tr>
                )}

                {invoice.items.length > 0 && <InvoiceSummary />}
            </tbody>
        </Wrapper>
    );
}
