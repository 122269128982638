import { addWeeks } from 'date-fns';
import without from 'lodash/without';
import { formatDate } from '../services/date';
import { storageGet } from '../services/storage';
import { createStore } from './index';

export enum ViewMode {
    Preview,
    Edit,
}

export interface Invoice {
    number: string;
    sentAt: Date;
    deliveryDate: Date | null;
    dueDate: Date;
    currency: string;
    items: InvoiceItem[];
    vatNumber: string;
    registrationCode: string;
    bankAccount: string;

    fromName: string;
    fromLogo?: string;
    fromPhone: string;
    fromEmail: string;
    fromStreet: string;
    fromCity: string;
    fromZipCode: string;
    fromCountry: string;

    toName: string;
    toStreet: string;
    toCity: string;
    toZipCode: string;
    toCountry: string;

    shippingStreet: string;
    shippingCity: string;
    shippingZipCode: string;
    shippingCountry: string;

    notes?: string;
    terms?: string;

    viewMode: ViewMode;

    isDownloading: boolean;
}

interface InvoiceItem {
    id: number;
    description: string;
    discount?: number;
    count: number;
    vat: number;
    priceIncludingVat: number;
}

const { useStore, reset, set } = createStore<Invoice>({
    number: formatDate(new Date(), 'yyyyMMdd'),
    sentAt: new Date(),
    deliveryDate: null,
    dueDate: addWeeks(new Date(), 2),
    currency: storageGet('currency') || 'EUR',
    vatNumber: storageGet('vatNumber') || '',

    fromName: storageGet('fromName') || '',
    fromLogo: storageGet('fromLogo') || '',
    registrationCode: storageGet('registrationCode') || '',
    bankAccount: storageGet('bankAccount') || '',
    fromPhone: storageGet('fromPhone') || '',
    fromEmail: storageGet('fromEmail') || '',
    fromStreet: storageGet('fromStreet') || '',
    fromCity: storageGet('fromCity') || '',
    fromZipCode: storageGet('fromZipCode') || '',
    fromCountry: storageGet('fromCountry') || '',

    toName: '',
    toStreet: '',
    toCity: '',
    toZipCode: '',
    toCountry: '',

    shippingStreet: '',
    shippingCity: '',
    shippingZipCode: '',
    shippingCountry: '',

    items: [
        {
            id: Math.random(),
            description: '',
            count: 1,
            vat: 20,
            priceIncludingVat: 0,
        },
    ],

    notes: '',
    terms: '',

    viewMode: ViewMode.Edit,

    isDownloading: false,
});

export function useInvoice() {
    const [invoice, setInvoice] = useStore();

    function findItem(id: number) {
        return invoice.items.find(item => item.id === id);
    }

    return {
        resetInvoice: reset,
        invoice,
        setInvoice,
        updateInvoice(object: any) {
            setInvoice(invoice => {
                Object.assign(invoice, object);
            });
        },
        addItem() {
            setInvoice(invoice => {
                const vat = (invoice.items.length > 0 ? invoice.items.slice(-1)[0].vat : 0) || 0;

                invoice.items.push({
                    id: Math.random(),
                    description: '',
                    count: 1,
                    vat,
                    priceIncludingVat: 0,
                });
            });
        },
        removeItemById(id: number) {
            setInvoice(invoice => {
                const item = invoice.items.find(item => item.id === id);

                if (item) {
                    invoice.items = without(invoice.items, item);
                }
            });
        },
        findItem,
        updateItem(id: number) {
            return (data: any) => {
                setInvoice(invoice => {
                    const item = invoice.items.find(item => item.id === id);

                    if (item) {
                        Object.assign(item, data);
                    }
                });
            };
        },
        calculateTotals(): { totalExcludingVat: number; totalIncludingVat: number; totalVat: number } {
            return invoice.items.reduce(
                (acc, item) => {
                    const totalInclVat = item.count * item.priceIncludingVat;
                    const totalExcludingVat = totalInclVat / (item.vat / 100 + 1);

                    acc.totalIncludingVat += totalInclVat;
                    acc.totalExcludingVat += totalExcludingVat;
                    acc.totalVat += totalInclVat - totalExcludingVat;

                    return acc;
                },
                {
                    totalExcludingVat: 0,
                    totalIncludingVat: 0,
                    totalVat: 0,
                },
            );
        },
        setViewMode(viewMode: ViewMode) {
            setInvoice(invoice => {
                invoice.viewMode = viewMode;
            });
        },
        isViewModeEdit() {
            return invoice.viewMode === ViewMode.Edit;
        },
        isViewModePreview() {
            return invoice.viewMode === ViewMode.Preview;
        },
    };
}

export function populateInvoiceWithMockData() {
    set({
        number: formatDate(new Date(), 'yyyyMMdd'),
        sentAt: new Date(),
        dueDate: addWeeks(new Date(), 2),
        deliveryDate: addWeeks(new Date(), 4),
        currency: 'EUR',
        vatNumber: 'AB123456789',

        fromName: 'Example From Ltd.',
        fromLogo:
            'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAABuElEQVR4nO3bMXLCMABFQZHhdL7/fUhDqhSBWINsv92GBjQqXmV/btu2PQZZ9+fnbektWOXxtfoGrCWAOAHECSBOAHECiBNAnADiBBAngDgBxAkgTgBxAoi7//2Vl9gUnNSsAMawKTgje4A6AcQJIE4AcQKIE0CcAOIEECeAOAHECSBOAHECiBNAnADiZu4B9jAoWeQoAYxhULKCQUidAOIEECeAOAHECSBOAHECiBNAnADiBBAngDgBxAkg7kivg/eyKfiHKwUwhk3Bu+wB6gQQJ4A4AcQJIE4AcQKIE0CcAOIEECeAOAHECSBOAHECiLvaHmCv3KhEAL+VRiUGIXUCiBNAnADiBBAngDgBxAkgTgBxAogTQJwA4gQQJ4A4AcTZA8x1ukGJAOY706DEIKROAHECiBNAnADiBBAngDgBxAkgTgBxAogTQJwA4gQQJ4A4e4Bj+figRADH88lBiUFInQDiBBAngDgBxAkgTgBxAogTQJwA4gQQJ4A4AcQJIM7r4Ot5a1MggGt6dVNgD1AngDgBxAkgTgBxAogTQJwA4gQQ9/MkcMZfkvaecYQ7zDhj9e/fOuMb1dEZ7gDl8kcAAAAASUVORK5CYII=',
        registrationCode: '0123012301230123',
        bankAccount: 'LHV EE210210210210',
        fromPhone: '+372 5555 5555',
        fromEmail: 'email@example.com',
        fromStreet: 'Street 1a-1',
        fromCity: 'Tallinn, Harju',
        fromZipCode: '10000',
        fromCountry: 'Estonia',

        toName: 'Example To Ltd.',
        toStreet: 'Street 2b-2',
        toZipCode: '20000',
        toCity: 'Tallinn, Harju',
        toCountry: 'Estonia',

        items: [
            {
                id: Math.random(),
                description: 'Hard work',
                count: 1,
                vat: 20,
                discount: 0,
                priceIncludingVat: 120,
            },
            {
                id: Math.random(),
                description: 'Even more hard work',
                count: 5,
                vat: 20,
                priceIncludingVat: 120,
            },
        ],

        shippingStreet: '',
        shippingCity: '',
        shippingZipCode: '',
        shippingCountry: '',

        notes: 'NB! Delivery date depends on payment of this invoice',
        terms: 'Interest of 0.01% applies per day when overdue',

        viewMode: ViewMode.Edit,
        isDownloading: false,
    });
}
