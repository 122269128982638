import Button from '@material-ui/core/Button';
import green  from '@material-ui/core/colors/green';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { toBase64 } from '../../services/file';
import { UiFilePreview } from './UiFilePreview';

const Wrapper = styled.div<any>`
    background-color: ${props => (props.isDragActive ? green[500] : 'transparent')};
    border-color: ${props => (props.isDragActive ? green[500] : '#aaa')};
    border-radius: 0.25rem;
    border-width: 1px;
    border-style: dashed;
    transition: background-color 300ms, color 300ms, border 300ms;
    height: 10rem;
    width: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${props => (props.isDragActive ? 'white' : 'inherit')};
    position: relative;
`;

const RemoveButtonWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
`;

interface Props {
    file?: File;
    fileBase64?: string;
    setFile?: (files: File) => void;
    setFileBase64?: (base64: string) => void;
    onRemove?: () => void;
}

export function UiFileSelect({ file, fileBase64, setFile, setFileBase64, onRemove }: Props) {
    const onDrop = useCallback(([file]) => {
        if (setFile) {
            setFile(file);
        }

        if (setFileBase64) {
            toBase64(file)
                .then(setFileBase64)
                .catch(console.error);
        }
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept: 'image/*', onDrop });
    const hasFile = Boolean(file || fileBase64);

    return (
        <Wrapper {...getRootProps()} isDragActive={isDragActive}>
            {onRemove && hasFile && (
                <RemoveButtonWrapper>
                    <Button
                        onClick={event => {
                            event.stopPropagation();
                            onRemove();
                        }}
                        color={'secondary'}
                        variant={'contained'}
                        size={'small'}
                    >
                        X
                    </Button>
                </RemoveButtonWrapper>
            )}

            <input {...getInputProps()} />

            <UiFilePreview file={file} fileBase64={fileBase64} />

            {!hasFile && <div style={{ padding: 16 }}>Drop logo here, or click to select</div>}
        </Wrapper>
    );
}
