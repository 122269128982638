import React from 'react';
import styled from 'styled-components';
import { useCurrencyConverter } from '../../hooks/currency-converter';
import { useInvoice } from '../../stores/invoice';
import sum from 'lodash/sum';

const Row = styled.tr`
    td:first-child {
        text-align: right;
    }

    td:last-child {
        font-weight: bold;
    }
`;

const RowTotal = styled(Row)`
    font-weight: bold;
`;

export function InvoiceSummary({}) {
    const { convert } = useCurrencyConverter();
    const { calculateTotals, isViewModeEdit, invoice } = useInvoice();
    const { totalExcludingVat, totalIncludingVat, totalVat } = calculateTotals();
    const colSpan = isViewModeEdit() ? 5 : 4;

    const vats = invoice.items.reduce((vats: any, item) => {
        vats[item.vat] = vats[item.vat] || [];
        vats[item.vat].push((Number(item.vat) / 100 + 1) * item.priceIncludingVat * item.count);
        return vats;
    }, {});

    const numUniqueVats = Object.keys(vats).length;

    return (
        <>
            <Row>
                <td colSpan={colSpan + 1}>&nbsp;</td>
            </Row>

            <Row>
                <td colSpan={colSpan}>Subtotal:</td>
                <td>{convert(totalExcludingVat)}</td>
            </Row>

            {numUniqueVats > 1 &&
                Object.keys(vats).map(vat => (
                    <Row>
                        <td colSpan={colSpan}>VAT ({vat}%):</td>
                        <td>{convert(sum(vats[vat]))}</td>
                    </Row>
                ))}

            {numUniqueVats === 1 && (
                <Row>
                    <td colSpan={colSpan}>VAT:</td>
                    <td>{convert(totalVat)}</td>
                </Row>
            )}

            <RowTotal>
                <td colSpan={colSpan}>Amount due:</td>
                <td>{convert(totalIncludingVat)}</td>
            </RowTotal>
        </>
    );
}
