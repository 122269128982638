import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import styled from 'styled-components';
import { storageClear, storageGet, storageSet } from '../../services/storage';
import { asyncComponent } from '../../services/util';
import { populateInvoiceWithMockData, useInvoice } from '../../stores/invoice';
import { FeedbackDialog } from '../FeedbackDialog';
import { UiFlex } from '../ui/UiFlex';
import { UiGrid } from '../ui/UiGrid';

const AsyncDownloadPdfButton = asyncComponent(() => import('../DownloadPdfButton'));

const Wrapper = styled(UiGrid)`
    padding: 2rem 1.5rem;

    h3 {
        margin: 0;
    }
`;

export function InvoiceSidebar() {
    const { invoice, resetInvoice } = useInvoice();
    const [isDev] = useState(Boolean(storageGet('dev')));

    function saveInfo() {
        for (const key of Object.keys(invoice)) {
            if (
                key.startsWith('from') ||
                ['currency', 'vatNumber', 'registrationCode', 'bankAccount', 'notes', 'terms'].includes(key)
            ) {
                storageSet(key, (invoice as any)[key] || '');
            }
        }
    }

    function resetInfo() {
        storageClear();
        resetInvoice();

        if (isDev) {
            storageSet('dev', '1');
        }
    }

    return (
        <Wrapper spacing={6}>
            <UiGrid>
                <Button onClick={populateInvoiceWithMockData} variant={'text'} color={'secondary'} fullWidth>
                    Fill in example invoice
                </Button>

                <UiFlex>
                    <Button onClick={resetInfo} variant={'contained'} fullWidth>
                        Reset
                    </Button>

                    <Button onClick={saveInfo} variant={'contained'} fullWidth>
                        Save
                    </Button>
                </UiFlex>

                <AsyncDownloadPdfButton />

                <FeedbackDialog />
            </UiGrid>
        </Wrapper>
    );
}
