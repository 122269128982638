import AppBar from '@material-ui/core/AppBar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import { useInvoice, ViewMode } from '../stores/invoice';

const useStyles = makeStyles({
    appBar: {
        backgroundColor: '#212121',
        zIndex: 1300,
    },
    tabs: {
        margin: '0 auto',
    },
    tab: {
        height: 64,
        //textTransform: 'initial',
        letterSpacing: 0,
        //fontSize: '1rem',
    },
    indicator: {
        height: 4,
    },
});

export function Header({}) {
    const classes = useStyles();
    const { invoice, setViewMode } = useInvoice();

    function handleChange(event: any, viewMode: ViewMode) {
        setViewMode(viewMode);
    }

    return (
        <AppBar position="sticky" className={classes.appBar}>
            <Toolbar>
                <Tabs
                    value={invoice.viewMode}
                    onChange={handleChange}
                    className={classes.tabs}
                    classes={{ indicator: classes.indicator }}
                >
                    <Tab label={'Edit'} value={ViewMode.Edit} className={classes.tab} />
                    <Tab label={'Preview'} value={ViewMode.Preview} className={classes.tab} />
                </Tabs>
            </Toolbar>
        </AppBar>
    );
}
