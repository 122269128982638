import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { useDate } from '../services/date';
import { theme } from '../theme';

interface Props {
    children: any;
}

export const Layout = ({ children }: Props) => {
    const { getLocale } = useDate();

    return (
        <>
            <MuiThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
                    <div>
                        <CssBaseline />

                        {children}
                    </div>
                </MuiPickersUtilsProvider>
            </MuiThemeProvider>
        </>
    );
};
