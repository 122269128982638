import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { storageGet } from './storage';

ReactGA.initialize('UA-148011023-1', {
    debug: Boolean(storageGet('debugGa')),
});

export enum AnalyticsCategory {
    Landing = 'Landing',
}

export enum AnalyticsAction {}

export const Analytics = {
    useTrackPageView(route: string) {
        useEffect(() => {
            ReactGA.pageview(route);
        }, []);
    },

    trackEvent(category: AnalyticsCategory, action: AnalyticsAction, extra?: { value?: number; label?: string }) {
        ReactGA.event({ category, action: String(action), ...extra });
    },
};
