import { format } from 'date-fns';
import localeEnGb from 'date-fns/locale/en-GB';
import { useState } from 'react';
import { createStore } from '../stores';

enum Locale {
    English = 'en-GB',
}

const { useStore } = createStore<any>({
    [Locale.English]: localeEnGb,
});

export function formatDate(date: Date, dateFormat = 'dd/MM/yyyy') {
    return format(date, dateFormat);
}

export function useDate() {
    const [locale] = useState(Locale.English);
    const [value] = useStore();

    return {
        getLocale() {
            return value[locale];
        },
    };
}
