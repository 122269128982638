import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div<Props>`
    display: flex;
    align-items: ${props => (props.alignCenter && 'center') || 'initial'};
    flex-flow: ${props => (props.column && 'column') || 'initial'};
    justify-content: ${props =>
        (props.justifySpaceBetween && 'space-between') || (props.justifyCenter && 'center') || 'initial'};
    width: ${props => (props.fullWidth ? '100%' : 'initial')};

    > *:not(:first-child) {
        margin-left: ${props => (props.spacing || 1) * 8}px;
    }

    ${props =>
        props.flexChildren &&
        css`
            > * {
                flex: 1;
            }
        `}
`;

interface Props {
    children: any;
    alignCenter?: boolean;
    justifyCenter?: boolean;
    justifySpaceBetween?: boolean;
    column?: boolean;
    fullWidth?: boolean;
    spacing?: number;
    className?: any;
    flexChildren?: boolean;
}

export function UiFlex({ children, ...rest }: Props) {
    return <Wrapper {...rest}>{children}</Wrapper>;
}
