import produce from 'immer';
import { useEffect, useState } from 'react';

type UpdateStateFunction<T> = (state: T) => void;

interface Store<T> {
    state: T;
    set(updateState: T | UpdateStateFunction<T>): void;
    subscribe(setState: Function): void;
    unsubscribe(setState: Function): void;
    useStore(): [T, (updateState: T | UpdateStateFunction<T>) => void];
    reset(): void;
}

export function createStore<T>(initialState?: T): Store<T> {
    const subscriptions = new Set<Function>();

    const store: Store<T> = {
        state: initialState,

        set(updateState) {
            const newState: T = updateState instanceof Function ? produce(store.state, updateState) : updateState;
            store.state = newState;
            subscriptions.forEach(setState => setState(newState));
        },

        subscribe(setState: Function): void {
            subscriptions.add(setState);
        },

        unsubscribe(setState: Function): void {
            subscriptions.delete(setState);
        },

        useStore() {
            const [state, setState] = useState(store.state);

            useEffect(() => {
                store.subscribe(setState);
                return () => store.unsubscribe(setState);
            }, []);

            return [state, store.set];
        },

        reset() {
            store.set(initialState as T);
        },
    } as Store<T>;

    return store;
}

export function useStore(store: any) {
    const [state, setState] = useState(store.state);

    useEffect(() => {
        store.subscribe(setState);
        return () => store.unsubscribe(setState);
    }, []);

    return [state, store.set];
}
