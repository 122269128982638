import Button from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';
import { useCurrencyConverter } from '../../hooks/currency-converter';
import { useInvoice } from '../../stores/invoice';
import { UiInput } from '../ui/UiInput';

const Row = styled.tr<any>`
    border-top: ${props => (props.isEdit ? 0 : 1)}px solid #ddd;

    :nth-child(even) {
        background-color: #fafafa;
    }
`;

export function InvoiceItemRow({ itemId }: any) {
    const { findItem, updateItem, removeItemById, isViewModeEdit, invoice } = useInvoice();
    const { convert } = useCurrencyConverter();

    const item = findItem(itemId);

    if (!item) {
        return null;
    }

    const { id, description, count, priceIncludingVat, vat } = item;
    const unitVat = priceIncludingVat / (vat / 100 + 1);
    const priceExcludingVat = priceIncludingVat / unitVat;
    const unitPrice = priceIncludingVat;
    const update = updateItem(item.id);

    return (
        <Row isEdit={isViewModeEdit()}>
            <td>
                {isViewModeEdit() ? (
                    <UiInput
                        value={description}
                        onChangeValue={(description: string) => update({ description })}
                        placeholder={'Description'}
                        disabled={invoice.isDownloading}
                    />
                ) : (
                    description || '-'
                )}
            </td>

            <td>
                {isViewModeEdit() ? (
                    <UiInput
                        value={count}
                        onChangeValue={(count: number) => update({ count })}
                        number
                        placeholder={'Count'}
                        disabled={invoice.isDownloading}
                    />
                ) : (
                    count
                )}
            </td>

            <td>
                {isViewModeEdit() ? (
                    <UiInput
                        value={priceIncludingVat}
                        onChangeValue={(priceIncludingVat: number) => update({ priceIncludingVat })}
                        currency
                        disabled={invoice.isDownloading}
                    />
                ) : (
                    convert(priceIncludingVat)
                )}
            </td>

            <td>
                {isViewModeEdit() ? (
                    <UiInput
                        value={vat}
                        onChangeValue={(vat: number) => update({ vat })}
                        number
                        placeholder={'VAT %'}
                        disabled={invoice.isDownloading}
                    />
                ) : (
                    <>{vat}%</>
                )}
            </td>

            <td>{convert(unitPrice * count)}</td>

            {isViewModeEdit() && (
                <td>
                    <Button
                        onClick={() => removeItemById(id)}
                        disabled={invoice.isDownloading}
                        variant={'text'}
                        color={'secondary'}
                    >
                        Remove
                    </Button>
                </td>
            )}
        </Row>
    );
}
