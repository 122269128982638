import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { apiFeedback } from '../backend-endpoints/feedback.[post]';
import { UiGrid } from './ui/UiGrid';

const Content = styled(DialogContent)`
    padding-top: 2rem !important;
`;

const Actions = styled(DialogActions)`
    padding: 1rem !important;
`;

export function FeedbackDialog() {
    const [open, setOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [text, setText] = useState('');

    useEffect(() => {
        if (!open) {
            setIsSending(false);
            setIsSent(false);
            setText('');
        }
    }, [open]);

    async function sendFeedback() {
        setIsSending(true);

        try {
            await apiFeedback({ body: { text } });
            setIsSent(true);
        } catch (error) {
            console.error(error);
        }

        setIsSending(false);
    }

    return (
        <>
            <Button onClick={() => setOpen(true)} fullWidth>
                Send Feedback
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <Content>
                    {isSent ? (
                        <UiGrid>
                            <Typography variant={'h3'}>Thank you for your feedback!</Typography>

                            <DialogContentText>
                                We really do appreciate it and value every piece of feedback we receive!
                            </DialogContentText>
                        </UiGrid>
                    ) : (
                        <UiGrid>
                            <Typography variant={'h3'}>What's on your mind?</Typography>

                            <DialogContentText>
                                Questions or comments? Features you'd like to see? Use this form to send your feedback.
                            </DialogContentText>

                            <TextField
                                label={'Feedback'}
                                multiline
                                rows={3}
                                value={text}
                                onChange={event => setText(event.target.value)}
                                variant={'outlined'}
                                disabled={isSending}
                            />
                        </UiGrid>
                    )}
                </Content>

                <Actions>
                    {isSent ? (
                        <Button onClick={() => setOpen(false)}>Close</Button>
                    ) : (
                        <>
                            <Button onClick={() => setOpen(false)}>Cancel</Button>

                            <Button onClick={sendFeedback} disabled={!text || isSending} color={'primary'}>
                                Send
                            </Button>
                        </>
                    )}
                </Actions>
            </Dialog>
        </>
    );
}
