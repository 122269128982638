import TextField from '@material-ui/core/TextField';
import { OutlinedInputProps } from '@material-ui/core/OutlinedInput';
import { BaseTextFieldProps } from '@material-ui/core/TextField';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import React from 'react';
import { Invoice, useInvoice } from '../../stores/invoice';

interface CreateTextFieldProps extends BaseTextFieldProps {
    label?: string;
    field: keyof Invoice;
    variant: 'standard' | 'filled' | 'outlined';
    InputProps?: Partial<OutlinedInputProps>;
    inputProps?: OutlinedInputProps['inputProps'];
}

export function InvoiceTextField({ label, field, variant, ...rest }: CreateTextFieldProps) {
    const { invoice, updateInvoice } = useInvoice();

    return (
        <TextField
            name={snakeCase(field)}
            label={label || startCase(field)}
            value={(invoice as any)[field]}
            onChange={event => updateInvoice({ [field]: event.target.value })}
            variant={variant as any}
            disabled={invoice.isDownloading}
            {...rest}
        />
    );
}
