import isEmpty from 'lodash/isEmpty';
import { stringify } from 'query-string';
import { storageGet } from './storage';

const API_URL = storageGet('dev')
    ? 'http://localhost:3210'
    : 'https://q0ybrvnnr4.execute-api.eu-west-1.amazonaws.com/prod';

interface RequestInput {
    queryParams?: any;
    params?: any;
    body?: any;
}

export const Api = {
    async get<T = any>(url: string, input: RequestInput): Promise<T> {
        return Api.request<T>({ url, method: 'GET', ...input });
    },

    async post<T = any>(url: string, input: RequestInput): Promise<T> {
        return Api.request<T>({ url, method: 'POST', ...input });
    },

    async put<T = any>(url: string, input: RequestInput): Promise<T> {
        return Api.request<T>({ url, method: 'PUT', ...input });
    },

    async delete<T = any>(url: string, input: RequestInput): Promise<T> {
        return Api.request<T>({ url, method: 'DELETE', ...input });
    },

    async request<T>({
        url,
        method,
        params,
        queryParams,
        body,
    }: {
        url: string;
        method: string;
        params?: any;
        queryParams?: any;
        body?: any;
    }): Promise<T> {
        url = isEmpty(queryParams) ? url : `${url}?${stringify(queryParams)}`;
        url = `${API_URL}/${url}`;
        url = Object.keys(params || {}).reduce((route, key) => route.replace(`:${key}`, params[key]), url);

        const fetchParams = {
            method,
            headers: await Api.getHeaders(),
            body: (body && JSON.stringify(body)) || null,
        };

        if (method.toLowerCase() === 'get') {
            delete fetchParams.body;
        }

        const response = await fetch(url, fetchParams);

        return Api.toJson(response);
    },

    async getHeaders(): Promise<{
        'Content-Type': string;
        Authorization: string;
    }> {
        const headers: any = {
            'Content-Type': 'application/json',
        };

        return headers;
    },

    async toJson(response: Response): Promise<any> {
        let parsed;

        try {
            parsed = await response.clone().json();
        } catch (error) {
            parsed = response.text();
        }

        if (response.status >= 400) {
            throw new Error(parsed.message || parsed);
        }

        return parsed;
    },
};
