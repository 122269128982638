import Typography from '@material-ui/core/Typography';
import React from 'react';
import styled from 'styled-components';
import { useInvoice } from '../../stores/invoice';
import { UiGrid } from '../ui/UiGrid';
import { InvoiceTextField } from './InvoiceTextField';

const Wrapper = styled.div`
    display: flex;
    padding-top: 1rem;
    border-top: 2px solid #ddd;
    font-size: 0.8rem;

    > * {
        flex: 1;
    }
`;

export function InvoiceFooter() {
    const { invoice, isViewModeEdit } = useInvoice();
    const { registrationCode, bankAccount, vatNumber, notes, terms } = invoice;

    return (
        <UiGrid>
            {isViewModeEdit() ? (
                <InvoiceTextField field={'notes'} variant={'outlined'} placeholder={'Insert notes for client'} />
            ) : (
                notes && (
                    <div>
                        <Typography variant={'h4'}>Notes</Typography>
                        <Typography variant={'body1'} color={'textSecondary'}>
                            {notes}
                        </Typography>
                    </div>
                )
            )}

            {isViewModeEdit() ? (
                <InvoiceTextField field={'terms'} variant={'outlined'} placeholder={'Insert terms for client'} />
            ) : (
                terms && (
                    <div>
                        <Typography variant={'h4'}>Terms</Typography>
                        <Typography variant={'body1'} color={'textSecondary'}>
                            {terms}
                        </Typography>
                    </div>
                )
            )}

            <Wrapper>
                <div>
                    {registrationCode && (
                        <div>
                            <b>Registration Code:</b>
                            {registrationCode}
                        </div>
                    )}
                    {vatNumber && (
                        <div>
                            <b>VAT Number:</b>
                            {vatNumber}
                        </div>
                    )}
                    {bankAccount && (
                        <div>
                            <b>Bank Account:</b>
                            {bankAccount}
                        </div>
                    )}
                </div>

                {/*<div>*/}
                {/*    {fromPhone && (*/}
                {/*        <div>*/}
                {/*            <b>Phone:</b> {fromPhone}*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*    {fromEmail && (*/}
                {/*        <div>*/}
                {/*            <b>E-mail:</b> {fromEmail}*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}
            </Wrapper>
        </UiGrid>
    );
}
