import store from 'store';

export function storageGet<T>(key: string): T {
    return store.get(key);
}

export function storageSet(key: string, value: any) {
    store.set(key, value);
}

export function storageRemove(key: string) {
    store.remove(key);
}

export function storageClear() {
    store.clearAll();
}
