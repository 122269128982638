import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<any>`
    > *:not(:first-child) {
        margin-top: ${props => props.spacing * 8}px;
    }
`;

interface Props {
    spacing?: number;
    children: any;
}

export function UiGrid({ spacing = 2, children, ...rest }: Props) {
    return <Wrapper spacing={spacing} {...rest}>{children}</Wrapper>;
}
