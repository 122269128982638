import Loadable from 'react-loadable';

export function asyncComponent(loader: any) {
    return Loadable({
        loader: async () => {
            try {
                const module = await loader();
                return module[Object.keys(module)[0]];
            } catch (error) {
                window.location.reload();
            }
        },
        loading: () => null,
    });
}
